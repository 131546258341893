var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['panel',
		_vm.isHeaderIndent? 'mt-0 pt-1' : '',
		_vm.isFooterIndent? 'mb-0 pb-1' : '',
		_vm.block.appearance? _vm.block.appearance : ''
	],style:(_vm.block.image ?
		'background-image:url(' + _vm.block.image + ')' : '')},[_c('div',{staticClass:"container"},[(_vm.isHeaderVisible)?_c('div',{class:[
				'panel_header',
				_vm.isBrand? 'sk' : '',
			]},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col-12"},[_c('h2',[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.block.block_title)+"\n\t\t\t\t\t")])])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"progress_block"},[_c('ul',{staticClass:"progress_list row"},_vm._l((_vm.block.items),function(item,index){return _c('li',{key:index,staticClass:"col-12 col-md-6 col-lg-4"},[_c('div',{staticClass:"progress-item"},[_c('div',[(item.icon)?_c('div',{staticClass:"h-4"},[(item.number)?_c('div',{class:['bus', item.icon]},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(item.number)+"\n\t\t\t\t\t\t\t\t")]):_vm._e(),_vm._v(" "),(item.title)?_c('div',{staticClass:"title"},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(item.title)+"\n\t\t\t\t\t\t\t\t")]):_vm._e()]):_c('div',{staticClass:"h-4"},[(item.number)?_c('div',{staticClass:"digit"},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(item.number)+"\n\t\t\t\t\t\t\t\t")]):_vm._e(),_vm._v(" "),(item.title)?_c('div',{staticClass:"title"},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(item.title)+"\n\t\t\t\t\t\t\t\t")]):_vm._e()]),_vm._v(" "),(item.description)?_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(item.description)}}):_vm._e()]),_vm._v(" "),_c('div',[(item.url)?_c('a',{staticClass:"btn btn-primary",attrs:{"href":item.url}},[_vm._v(" "+_vm._s(item.url_title))]):_vm._e()])])])}),0)]),_vm._v(" "),(_vm.isFooterVisible)?_c('div',{staticClass:"panel_footer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12",domProps:{"innerHTML":_vm._s(_vm.block.footer_text)}})])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }