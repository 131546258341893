<template>
	<div
		:class="['panel',
			isHeaderIndent? 'mt-0 pt-1' : '',
			isFooterIndent? 'mb-0 pb-1' : '',
			block.appearance? block.appearance : ''
		]"
		:style="block.image ?
			'background-image:url(' + block.image + ')' : ''"
	>
		<div class="container">
			<div
				v-if="isHeaderVisible"
				:class="[
					'panel_header',
					isBrand? 'sk' : '',
				]"
			>
				<div class="row align-items-end">
					<div class="col-12">
						<h2>
							{{ block.block_title }}
						</h2>
					</div>
				</div>
			</div>

			<div class="progress_block">
				<ul class="progress_list row">
					<li
						v-for="(item, index) in block.items"
						:key="index"
						class="col-12 col-md-6 col-lg-4"
					>
						<div class="progress-item">

							<div>
								<div class="h-4" v-if="item.icon">
									<div v-if="item.number" :class="['bus', item.icon]">
										{{ item.number }}
									</div>
									<div v-if="item.title" class="title">
										{{ item.title }}
									</div>
								</div>
								<div class="h-4" v-else>
									<div v-if="item.number" class="digit">
										{{ item.number }}
									</div>
									<div v-if="item.title" class="title">
										{{ item.title }}
									</div>
								</div>
								<div v-if="item.description" class="description" v-html="item.description" />
							</div>

							<div>
								<a
									v-if="item.url"
									:href="item.url"
									class="btn btn-primary"
								> {{ item.url_title }}</a>
							</div>

						</div>
					</li>
				</ul>
			</div>


			<div v-if="isFooterVisible" class="panel_footer">
				<div class="row">
					<div class="col-12" v-html="block.footer_text" />
				</div>
				<!--<panel-footer v-else :block="block"/>-->
			</div>
		</div>
	</div>
</template>

<script>
import panel from '~/mixins/panel';

export default {
	name: 'PanelCardsOnBackground',
	mixins: [panel],
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/panel-school-progress";
	.h-4 {
		min-height: 4rem;
		height: auto;
	}
</style>
